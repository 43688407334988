.blanker {
  width: 100%;
  height: 6.6vh;
  background-color: #1a1a1a;
  position: fixed;
  z-index: 0;
  margin-top: -10px;
}
@media all and (max-width: 1366px) {
  .blanker {
    height: 4.5vh;
  }
}
