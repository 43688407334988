/*CSS FOR GRID'ING THE PAGE*/
/*GRID TEMPLATE FOR GENERAL PAGE */
.results_root {
  width: 100%;
  height: 99vh;
  margin-top: 1vh;
  display: grid;
  grid-template-columns: 240px auto;
  grid-template-rows: 30px auto;
  grid-template-areas:
    "sidemenu stuffSelector"
    "sidemenu content";
  column-gap: 1.2rem;
  row-gap: 0.3rem;
}
.results_side_menu {
  grid-area: sidemenu;
}
.results_date_selector {
  grid-area: stuffSelector;
  z-index: 1;
  position: fixed;
  width: calc(100% - 260px);
  height: 36px;
  margin-left: 260px;
}
.results_content {
  grid-area: content;
  margin-top: 12px;
}
/*GRID TEMPLATE FOR SIDEMENU */
.side_menu_root {
  display: grid;
  grid-template-columns: 240px;
  grid-template-rows: 42px auto auto;
  grid-template-areas:
    "verifyTicket"
    "topidx"
    "defaultMenu";
  margin-left: 0.6rem;
  row-gap: 1rem;
  position: fixed;
}
.side_menu_verify_ticket {
  grid-area: verifyTicket;
}
.side_menu_top_index {
  grid-area: topidx;
}
.side_menu_generic {
  grid-area: defaultMenu;
}
/*CSS FOR TOP INDEX LOTTERIES*/
.side_menu_top_index {
  background-color: #282828;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid black;
  border-bottom: none;
}
.top_idx_single_element {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid black;
  text-align: center;
  cursor: pointer;
  display: flex;
}
.top_idx_single_element:hover {
  background-color: #666262;
}
.top_idx_logo {
  width: 30px;
  display: flex;
  align-items: center;
}
.top_idx_span {
  width: calc(100% - 110px);
  margin-left: 6px;
  color: whitesmoke;
  font-size: 12px;
  font-weight: 500;
  line-height: 3.3;
  text-align: left;
}
.top_idx_logo_i {
  margin-left: 6px;
  margin-right: 12px;
  border-radius: 45%;
  border: 1px solid black;
}
.top_idx_balls_logo {
  width: 46px;
  display: flex;
  align-items: center;
}
.top_idx_guess {
  background-color: rgb(207, 207, 207);
  border: 1px solid black;
  color: black;
  margin-right: -2px;
  z-index: 1;
  border-radius: 11px;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  width: 22px;
}
.top_idx_total {
  background-color: #7a7a7a;
  color: black;
  border: 1px solid black;
  border-radius: 11px;
  font-size: 12px;
  height: 22px;
  line-height: 20px;
  text-align: center;
  width: 22px;
}
/*GRID FOR GENERIC SIDE MENU*/
.generic_sidemenu_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px auto;
  grid-template-areas: "tabs" "genericMenu";
  row-gap: 0.3rem;
}
.generic_tabs {
  grid-area: tabs;
}
.generic_list {
  grid-area: genericMenu;
}
/*CSS FOR GENERIC SIDE MENU*/
.generic_list {
  background-color: #282828;
  border-radius: 6px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: calc(100vh - 350px);
}
.generic_list::-webkit-scrollbar {
  width: 7px;
  background-color: #282828;
}
.generic_list::-webkit-scrollbar-track {
  background-color: rgba(51, 51, 51, 0.63);
}
.generic_list::-webkit-scrollbar-thumb {
  background-color: #f47f32;
}
.generic_side_tabs {
  width: 100%;
  background-color: #282828;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  height: 36px;
  display: flex !important;
  z-index: 50;
  position: relative;
  text-transform: uppercase;
}
.generic_side_tab {
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 4px;
  border-right: 1px solid black;
  font-size: 12px;
  color: #f47f32;
  font-weight: 500;
  cursor: pointer;
  text-transform: uppercase;
}
.generic_side_tab:hover {
  background-color: #666262;
}
.generic_side_tab a {
  all: unset;
}
.generic_side_tab_active {
  border-bottom: 2px solid #f47f32;
  background-color: #282828;
}
/*CSS FOR MENU INCLUDING LOTTERIES OR SYSTEM TABS*/
.all_button_country_item {
  text-align: left;
  border-bottom: 1px solid black;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  color: whitesmoke;
  width: 100%;
}
.all_button_span {
  text-transform: uppercase;
  margin-left: 18px;
  width: 66%;
}
.all_button_country_item:hover {
  background-color: #666262;
}
.all_button_icon {
  border-radius: 50%;
  margin-top: 2.4%;
  margin-left: 1%;
}
.all_button_country_item_active {
  background-color: #666262;
  border-left: 2px solid #f47f32;
}
.all_button_camera {
  padding-top: 3px;
}
.generic_country_subitem {
  box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid black;
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  cursor: pointer;
  color: #b4b4b4;
  text-transform: uppercase;
  width: 100%;
  display: flex;
}
.generic_subitem_spaning {
  width: 90%;
  margin-left: 15px;
}
.subitem_active {
  background-color: rgba(234, 6, 10, 0.05);
}
.generic_country_subitem:hover {
  background-color: darkgray;
  color: darkslategrey;
}
.generic_subitem_camera {
  padding-top: 3px;
}
.generic_list_root_sys {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 8px 12px;
  z-index: 10;
}
.system_flexin_half {
  width: 50%;
}
.generic_list_all_button_sys {
  display: inline-flex;
  flex: 0 0 100%;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.generic_system_item_label {
  font-size: 14px;
  color: #b4b4b4;
  margin-left: 3px;
}
.generic_system_item_span {
  min-height: 16px;
  vertical-align: middle;
  color: #b4b4b4;
  text-align: left;
  font-weight: bold;
  line-height: 2;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  color: #b4b4b4;
  text-transform: uppercase;
}

.generic_list_rest_items_sys {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px 24px 0px 0px;
  z-index: 10;
}
.generic_system_item_root {
  display: inline-flex;
  flex: 0 0 50%;
  padding-right: 21px;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*CSS FOR DATE SELECTOR COMPONENT*/
.date_selector_root {
  display: flex;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  height: 100%;
  z-index: 12150;
  background-color: #1a1a1a;
}
.date_selector_button {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border: 1px solid black;
  border-radius: 6px;
  font-size: 12px;
  color: #b4b4b4;
  font-weight: 500;
  cursor: pointer;
  height: 21px;
  background-color: #1a1a1a;
}
.date_selector_is_active {
  border-bottom: 2px solid #f47f32;
  background-color: #666262;
}
.date_selector_button:hover {
  background-color: #666262;
}
.date_selector_button a {
  all: unset;
}

/*GRID FOR CONTENT AREA*/
.content_results_root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 36px minmax(auto, calc(100vh - 15vh));
  grid-template-areas: "resultMinih" "resultContentLisst";
  row-gap: 0.1rem;
}
.content_results_miniheader {
  grid-area: resultMinih;
}
.content_results_list {
  grid-area: resultContentLisst;
}
.content_results_miniheader {
  width: calc(100% - 260px);
  display: flex;
  align-items: center;
  background-color: #282828;
  font-size: 10px;
  color: #b4b4b4;
  position: sticky;
  z-index: 40;
  margin-top: 6px;
  height: 30px;
  position: fixed;
}
.content_results_minih_date {
  width: 10%;
  margin-left: 9px;
}
.content_results_minih_label {
  width: 20%;
}
.content_results_minih_nums {
  width: 69%;
}
/*CSS FOR SINGLE ROW CONTENT*/
.content_results_single_row_root {
  display: flex;
  color: #333;
  font-size: 12px;
  border-bottom: 1px solid black;
  background-color: #282828;
  height: 30px;
}
.content_results_single_row_date {
  width: 10%;
  margin-left: 9px;
  color: #b4b4b4;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  line-height: 3;
}
.content_results_single_row_label {
  width: 30%;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.5;
  text-align: left;
  color: whitesmoke;
}
.results_single_row_name {
  width: 50%;
}
.results_single_row_system {
  display: flex;
}
.results_single_row_guess {
  margin-top: 6px;
  background-color: rgb(207, 207, 207);
  border: 1px solid #777;
  color: black;
  margin-right: -2px;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
}
.results_single_row_outof {
  margin-top: 6px;
  background-color: #7a7a7a;
  color: black;
  border-radius: 11px;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  width: 18px;
  border: 1px solid transparent;
}
.content_results_single_row_numbers {
  display: flex;
  flex-wrap: wrap;
}
.content_results_single_row_num {
  margin-top: 3px;
  display: inline-block;
  background-color: rgba(244, 127, 50, 0.8);
  height: 24px;
  width: 24px;
  margin-right: 6px;
  text-align: center;
  line-height: 2;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
  color: black;
}

/*CSS FOR TICKET VERIFICATION*/
.ticket_verify_root {
  width: 210px;
  border-radius: 4px;
  background-color: #282828;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  padding: 6px;
  z-index: 105;
  display: flex;
  color: #f47f32;
}
.ticket_verify_right {
  display: flex;
  margin-left: 6px;
}
.ticket_verify_intup {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
  font-size: 12px;
  outline: 0;
  padding: 0 6px;
  color: black;
  height: 24px;
  line-height: 24px;
  background-color: darkgray;
}
.ticket_verify_button {
  cursor: pointer;
  outline: 0;
  color: black;
  background-color: #f47f32;
  height: 26px;
  width: 26px;
  align-items: center;
  border: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-left: 3px;
}
.material_icons {
  margin-left: 5px;
  margin-top: 5px;
}
/*CSS FOR VERIFY TICKET MODAL*/
.modal-ticket {
  z-index: 12999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);
  overflow: hidden;
}
.modal-container {
  width: 656px;
  max-width: 100%;
  background-color: #2e2e2e;
  border-radius: 4px;
  margin: 120px auto 60px;
  position: relative;
}
.modal-ticket-header {
  display: flex;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid black;
  background-color: #f47f32;
}
.modal-ticket-header-title {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-left: 9px;
  text-transform: uppercase;
  color: #333;
}
.modal-ticket-header-icon {
  display: inline-block;
  line-height: 1;
  text-align: center;
  font-size: 24px;
  width: 24px;
  height: 24px;
  color: #333;
  margin-right: 9px;
  cursor: pointer;
}
.modal-ticket-subheader {
  background-color: #2e2e2e;
  padding-left: 12px;
  color: whitesmoke;
  font-size: 12px;
  border-bottom: 1px solid black;
  min-height: 28px;
  line-height: 16px;
  display: flex;
}
.modal-ticket-subheader-numbers {
  width: 30%;
  margin-top: 6px;
}
.modal-ticket-subheader-numbers span {
  font-weight: 200;
  font-size: 13px;
  color: whitesmoke;
  margin-left: 3px;
}
.modal-ticket-subheader-cota {
  width: 68%;
  margin-top: 6px;
}
.modal-ticket-subheader-cota span {
  font-weight: 400;
  font-size: 13px;
  color: whitesmoke;
  margin-left: 3px;
}
.modal-ticket-content {
  display: flex;
  padding-bottom: 1px;
  border-radius: 0 0 4px 4px;
  width: 100%;
}
.modal-ticket-left-wrap {
  border-right: 1px solid black;
  position: inherit;
  width: 48%;
  padding: 3px 6px;
}
.modal-ticket-left {
  margin-top: 9px;
  background-color: #363636;
  height: fit-content;
  border-radius: 3px;
  color: whitesmoke;
}
.modal-ticket-date-time {
  color: whitesmoke !important;
  font-size: 10px;
  font-weight: 300;
  margin-right: 12px;
  text-transform: uppercase;
  text-align: left;
  padding: 6px;
}
.modal-ticket-name {
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  width: 200px;
  text-align: left;
  padding: 6px;
  color: whitesmoke;
}
.ticket_ticket_ball {
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 35px;
  height: 35px;
  margin-right: 3px;
  margin-left: 6px;
  margin-bottom: 9px;
  position: relative;
  color: black;
}
.ticket_ticket_ball span {
  display: block;
  width: 27px;
  height: 27px;
  line-height: 27px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid darkgray;
  background-color: white;
  font-size: 15px;
  font-weight: 500;
  margin-top: 2px;
  margin-right: 2px;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.ticket_ticket_ball span:hover {
  background-color: #f47f32;
  color: white;
}
.modal-ticket-right {
  display: block;
  overflow: visible;
  width: 50%;
}
.modal-ticket-serial {
  padding: 12px;
  line-height: 20px;
  text-align: center;
  color: whitesmoke;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  width: 300px;
}
.modal-ticket-serial svg {
  width: 300px !important;
}
.modal-ticket-serial > svg > rect {
  fill: rgb(46, 46, 46) !important;
}
.modal-ticket-serial > svg > g {
  fill: whitesmoke !important;
}
.modal-ticket-serial > svg > g > text {
  color: whitesmoke !important;
  font-size: 24px !important;
}
.modal-ticket-mize {
  border-top: 1px solid black;
  padding: 12px 0 8px 0;
}
.modal-ticket-mize-ct {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-miza {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-ct-num {
  font-weight: 500;
  font-size: 14px;
  margin-right: 6px;
}
.modal-ticket-mize-ct-num span {
  font-weight: 400;
  color: whitesmoke;
  font-size: 12px;
}
.modal-ticket-mize-taxa {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-mize-mizataxa {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  font-weight: 500;
  margin-left: 6px;
  font-size: 14px;
  margin-bottom: 9px;
  margin-right: 6px;
}
.modal-ticket-buttons {
  border-top: 1px dashed black;
  padding: 12px 0 8px 0;
}
.modal-ticket-pot-win {
  display: flex;
  text-align: center;
  justify-content: space-between;
  color: whitesmoke;
  margin-left: 6px;
  font-size: 18px;
  font-weight: 700;
  line-height: 16px;
  margin-right: 6px;
}
.modal-ticket-pot-win-win span {
  font-weight: 400;
  color: whitesmoke;
  font-size: 12px;
  margin-right: 6px;
}
.modal-tickets-btns {
  display: flex;
  margin-top: 6%;
}
.modal-ticket-close-btn {
  cursor: pointer;
  background-color: #ea060a;
  border: 0;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  outline: 0;
  text-transform: uppercase;
  margin-right: 8px;
  border: 1px solid #777;
  color: whitesmoke;
  background-color: transparent;
  margin-left: 6px;
}
.modal-ticket-print-btn {
  cursor: pointer;
  background-color: #f47f32;
  border: 0;
  border-radius: 4px;
  color: #fff;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  outline: 0;
  text-transform: uppercase;
  margin-right: 6px;
}
.modal-ticket-close-btn:hover {
  background-color: #e3e3e3;
}
.modal-ticket-print-btn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.bar-bar-code {
  height: 10px;
}

/*PREPARE FOR CSS MOBILE*/
.results_side_menu_mobile {
  display: none;
}

/*CSS FOR MOBILE WORKFLOW*/
@media all and (max-width: 1280px) {
  .results_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 36px auto;
    grid-template-areas: "sidemenumobile" "content";
    row-gap: 0;
    column-gap: 0;
  }
  .results_side_menu_mobile {
    display: block;
  }
  .results_side_menu {
    display: none;
  }
  .results_date_selector {
    display: none;
  }
}
/*@@@--Side menu for mobile--@@@*/
.side_mobile_root {
  display: grid;
  width: 100%;
  height: 54px;
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 100%;
  grid-template-areas: "mobile_results mobile_date mobile_tickets";
  position: fixed;
  z-index: 500;
  background-color: #f47f32;
  border-radius: 0;
  margin-top: -6px;
}
.s_side_mobile_date {
  grid-area: mobile_date;
}
.s_side_mobile_results {
  grid-area: mobile_results;
}
.s_side_mobile_tickets {
  grid-area: mobile_tickets;
}
.s_side_mobile_date,
.s_side_mobile_results {
  border-right: 1px solid black;
  text-align: center;
  font-size: 90%;
  font-weight: 600;
  display: block;
}
.s_side_mobile_tickets {
  text-align: center;
  font-size: 90%;
  font-weight: 600;
}
.mwrap {
  display: flex;
  flex-direction: column;
}
.m_icon {
  text-align: center;
  margin: auto;
  margin-top: 2px;
}
@media all and (max-width: 1280px) {
  .content_results_miniheader {
    width: 100%;
    position: unset;
  }
}
@media all and (max-width: 1440px) {
  .content_results_root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 36px auto;
    grid-template-areas: "miniHeader" "contentList";
    row-gap: 0;
  }

  .content_results_miniheader {
    grid-area: miniHeader;
  }
  .content_results_list {
    grid-area: contentList;
  }
}
@media all and (max-width: 1540px) {
  .content_results_single_row_root {
    display: block;
    color: #333;
    height: 120px;
  }
  .content_results_single_row_date {
    width: 100%;
    height: 30px;
    margin-left: 9px;
  }
  .content_results_single_row_label {
    width: 100%;
    height: 30px;
    margin-left: 9px;
  }
  .results_single_row_name {
    width: auto;
    margin-right: 9px;
  }
  .content_results_single_row_numbers {
    height: 30px;
    width: 100%;
    margin-left: 9px;
  }
}
.side_mobile_menu {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
}
.side_mobile_wrapper {
  width: 250px;
  max-width: 100%;
  border-radius: 4px;
  position: relative;
}
.side_mobile_dateselector {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
}
.side_mobile_date_wrapper {
  width: 250px;
  max-width: 100%;
  border-radius: 4px;
  margin-top: 51px;
}
.date_selector_root_m {
  display: block !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
  height: 100%;
  z-index: 12150;
  background-color: #1a1a1a;
}
.side_mobile_verify {
  z-index: 12399;
  top: 0;
  left: 0;
  width: 100%;
  height: 18%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: fixed;
}
.side_mobile_verify_wrapper {
  width: 250px;
  max-width: 100%;
  border-radius: 4px;
  position: relative;
  margin-top: 60px;
  margin-left: 72px;
}
@media all and (max-width: 786px) {
  .modal-ticket {
    top: 100 !important;
    left: 100 !important;
    min-height: 240px;
    max-height: 510px;
    margin-top: 7vh;
    background-color: rgba(0, 0, 0, 0.48);
    margin-top: 120px;
    overflow: auto;
  }
  .modal-container {
    all: unset;
    display: grid !important;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas: "mob_head" "mob_subhead" "mob_cntnt";
    background-color: rgb(46, 46, 46);
  }
  .modal-ticket-header {
    grid-area: mob_head;
  }

  .modal-ticket-subheader {
    grid-area: mob_subhead;
    justify-content: space-evenly;
    width: 100%;
  }
  .modal-ticket-subheader-numbers {
    width: auto;
    float: left;
  }
  .modal-ticket-subheader-cota {
    width: auto;
    float: right;
  }

  .modal-ticket-content {
    grid-area: mob_cntnt;
  }
  .modal-ticket-content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-template-areas: "mob_stuff" "mob_numbers";
  }
  .modal-ticket-left {
    overflow-y: auto;
    grid-area: mob_numbers;
  }
  .modal-ticket-right {
    grid-area: mob_stuff;
  }
  .modal-ticket-right {
    width: 100% !important;
  }
  .modal-ticket-serial {
    width: 100% !important;
    padding: 0 !important;
    margin: auto !important;
  }
  .modal-ticket-left-wrap {
    width: 100% !important;
    margin-bottom: 18px !important;
  }
}
